import request from '@/utils/request'

// 联系我们
export function handleContactUs(data) {
    return request({
        url: '/door/addUserMsg',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 概览
export function handleSituation() {
    return request({
        url: '/door/getIntroduceInfo',
        headers: {
            isToken: false
        },
        method: 'get',
    })
}

// 专业荣誉
export function handleHonor(data) {
    return request({
        url: '/door/searchHonorList',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}