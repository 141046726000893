<template>
  <div class="top-wrap">
    <div class="top-img">
      <img src="../../assets/images/top-img.png" alt="" />
    </div>
    <div class="mask"></div>

    <div class="navigatoin-wrap">
      <navigation></navigation>
    </div>
  </div>
</template>

<script>
import navigation from "../Navigation/index";

export default {
  components: {
    navigation,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.top-wrap {
  position: relative;
  width: 100%;
  height: 380px;
}

.top-wrap .top-img,
.top-wrap .top-img img {
  width: 100%;
  height: 100%;
}

.top-wrap .mask {
  position: absolute;
  z-index: -1;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}

.navigatoin-wrap {
  margin: 0 auto;
  width: 90%;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: flex-end;
}
</style>
