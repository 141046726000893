<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间区域 -->
    <div class="center-wrap">
      <!-- 面包屑区 -->
      <div class="breadcrumb-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>关于我们</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="situation-content-wrap" id="situation">
        <div class="title-wrap">
          <span>南&nbsp;君&nbsp;概&nbsp;览</span>
          <div class="title-line"></div>
        </div>

        <div class="situation-content" v-html="situation"></div>
      </div>

      <div class="honor-wrap" id="honor">
        <div class="title-wrap">
          <span>专&nbsp;业&nbsp;荣&nbsp;誉</span>
          <div class="title-line"></div>
        </div>

        <div class="scroll-wrap">
          <vue-seamless-scroll
            :data="scrollData"
            :class-option="classOption"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in scrollData"
                :key="index"
              >
                <img
                  :src="serverURL + item.img"
                  alt=""
                  class="swiperImg"
                  preview="item.id"
                />
                <span v-text="item.name"></span>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { handleSituation, handleHonor } from "@/api/AboutUs";
import global from "@/utils/global.js";
import VueSeamlessScroll from "vue-seamless-scroll";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
    VueSeamlessScroll,
  },

  data() {
    return {
      //   页面标题
      textTitle: "关于我们",

      // 滚动条数据
      scrollData: [],

      // 滚动条配置
      classOption: {
        direction: 2,
        limitMoveNum: 2,
      },

      // 概览内容
      situation: "",

      // 服务器路径
      serverURL: global.httpUrl,
    };
  },

  mounted() {
    if (window.location.hash.indexOf("honor") != -1) {
      this.goAnchor("honor");
    }

    if (window.location.hash.indexOf("situation") != -1) {
      this.goAnchor("situation");
    }

    this.getData();
  },

  methods: {
    // 定位锚点
    goAnchor(selector) {
      document.getElementById(selector).scrollIntoView();
    },

    // 获取页面数据
    getData() {
      handleSituation().then((response) => {
        this.situation = response.data.content;
      });

      const data = {};
      handleHonor(data).then((response) => {
        this.scrollData = response.data;
        this.$previewRefresh();
      });
    },
  },
};
</script>

<style scoped>
.situation-content-wrap {
  width: 100%;
  padding: 0 20px;
}

.title-wrap {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-wrap span {
  display: block;
  font-size: 36px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.title-line {
  margin-top: 5px;
  width: 72px;
  height: 6px;
  background: #ff8516;
  border-radius: 3px;
}

.situation-content {
  margin: 25px auto 0;
  width: 100%;
}

::v-deep .situation-content > p:nth-child(1) {
  float: right;
}

::v-deep .situation-content span {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: 400;
  color: #000;
  line-height: 2;
}

.situation-content img {
  float: right;
  width: 408px;
  height: 231px;
}

.situation-content span {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
  line-height: 2;
}

.honor-wrap {
  margin: 45px auto 0;
  width: 100%;
  padding: 0 20px;
}

.scroll-wrap {
  margin: 25px auto 0;
  width: 100%;
  height: 340px;
}

.warp {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.warp ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.ul-item {
  display: flex;
}

.li-item {
  width: 196px;
  height: 360px;
  margin-right: 10px;
  line-height: 60px;
}

.li-item span {
  color: #1f1f1f;
  font-size: 18px;
}

.swiperImg {
  width: 196px;
  height: 270px;
  object-fit: cover;
}
</style>
