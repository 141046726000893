<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间区域 -->
    <div class="center-wrap">
      <!-- 面包屑区 -->
      <div class="breadcrumb-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/AboutUs/SituationAndHonor' }"
            >关于我们</el-breadcrumb-item
          >
          <el-breadcrumb-item>联系我们</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <!-- 留言区 -->
      <div class="messageBoard-wrap">
        <div class="title-wrap">
          <span>在&nbsp;线&nbsp;留&nbsp;言</span>
          <div class="title-line"></div>
        </div>

        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <div class="user-info-wrap">
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>

            <el-form-item label="手机：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入您的手机号"
              ></el-input>
            </el-form-item>

            <el-form-item label="邮箱：" prop="email">
              <el-input
                v-model="form.email"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>
          </div>

          <div class="board-wrap">
            <el-form-item label-width="0" prop="content">
              <el-input
                type="textarea"
                :rows="10"
                placeholder="需求描述"
                v-model="form.content"
                resize="none"
              >
              </el-input>
              <img src="../../assets/images/board-pen.png" alt="" />
            </el-form-item>
          </div>

          <el-button class="submit-btn" @click="submitForm('form')"
            >提&nbsp;&nbsp;交</el-button
          >
        </el-form>
      </div>

      <!-- 地图区 -->
      <div class="map-wrap">
        <div class="title-wrap">
          <span>我&nbsp;们&nbsp;在&nbsp;这</span>
          <div class="title-line"></div>
        </div>

        <div class="baidumap">
          <baidu-map
            :center="center"
            :zoom="zoom"
            @ready="handler"
            style="height: 720px"
            :scroll-wheel-zoom="true"
          >
          </baidu-map>
        </div>
      </div>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { handleContactUs } from "@/api/AboutUs";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
  },

  data() {
    // 手机号正则表达式
    var tel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

    // 验证手机号
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入您的手机号"));
      } else if (!tel.test(value)) {
        callback(new Error("手机号输入有误"));
      } else {
        callback();
      }
    };

    // 邮箱正则表达式
    var email = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

    // 验证邮箱
    var validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入您的邮箱"));
      } else if (!email.test(value)) {
        callback(new Error("邮箱输入有误"));
      } else {
        callback();
      }
    };

    return {
      //   页面标题
      textTitle: "联系我们",

      // 表单内容
      form: {
        name: "",
        phone: "",
        email: "",
        content: "",
      },

      // 表单验证
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        email: [{ validator: validateEmail, trigger: "blur" }],
        content: [
          { required: true, message: "请输入您的需求", trigger: "blur" },
        ],
      },

      //   地图参数
      center: { lng: 114.348281, lat: 30.510935 },
      zoom: 19,
    };
  },

  methods: {
    // 初始化地图
    handler({ BMap, map }) {
      var point = new BMap.Point(this.center.lng, this.center.lat);
      map.centerAndZoom(point, this.zoom);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中

      var opts = {
        width: 290, // 信息窗口宽度
        height: 80, // 信息窗口高度
        title: "湖北南君律所", // 信息窗口标题
      };
      var infoWindow = new BMap.InfoWindow(
        "地址：武汉市洪山区书城路文秀街10号中石A栋大楼2号门第5层504室",
        opts
      ); // 创建信息窗口对象
      map.openInfoWindow(infoWindow, map.getCenter()); // 打开信息窗口
    },

    // 提交表单
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // console.log(this.form);
          handleContactUs(this.form).then((response) => {
            this.$message({
              message: "您的需求已成功提交",
              type: "success",
            });
          });
        } else {
          this.$message.error("部分信息未正确填写");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.messageBoard-wrap {
  width: 100%;
}

.title-wrap {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-wrap span {
  display: block;
  font-size: 36px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.title-line {
  margin-top: 5px;
  width: 72px;
  height: 6px;
  background: #ff8516;
  border-radius: 3px;
}

.user-info-wrap {
  margin: 25px auto 0;
  width: 838px;
  display: flex;
}

.username,
.phone,
.email {
  flex: 30%;
  display: flex;
  align-items: center;
}

::v-deep .el-form-item__label {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #282828;
}

::v-deep .el-input {
  margin-bottom: 3px;
  width: 100%;
  border-bottom: 1px solid rgba(40, 40, 40, 0.5);
}

::v-deep .el-input__inner {
  border: none;
  color: #9b9b9b;
}

.board-wrap {
  margin: 25px auto 0;
  position: relative;
  width: 999px;
  height: 212px;
}

.el-textarea {
  border: none;
  outline: none;
}

.board-wrap img {
  position: absolute;
  top: -5px;
  left: 100px;
  width: 122px;
  height: 15px;
}

.submit-btn {
  margin: 35px auto 0;
  width: 134px;
  height: 45px;
  background: #ff8516;
  border-radius: 10px;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.baidumap {
  margin: 45px auto 0;
  width: 1077px;
}

::v-deep .BMap_cpyCtrl {
  display: none !important;
}

::v-deep .anchorBL {
  display: none !important;
}
</style>
